// src/index.tsx
import React from 'react';
import './styles/index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store/store';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
);
