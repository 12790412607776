import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/login/login';
import JobSiteListPage from './pages/jobSiteList/jobSiteList';
import './styles/App.scss';
import Header from './pieces/header/header';
import EmployeeList from './pages/employeeList/employeeList';
import ProtectedRoute from './guards/ProtectedRoute';
import MapPage from './pages/mapPage/mapPage';
import UserList from "./pages/userList/userList";
import ProtectedRouteSuperAdmin from "./guards/ProtectedRouteSuperAdmin";
import CompaniesPage from "./pages/companyList/companyList";
import CompanyPage from "./pages/companyList/companyDetails";
import JobSitePage from "./pages/jobSite/jobSite";
import ProtectedRouteAdmin from "./guards/ProtectedRouteAdmin";
import ReportsPage from "./pages/reports/reports";
import SettingsPage from "./pages/settings/settings";
import CheckinPage from "./pages/checkin/checkin";

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/checkin/:jobSiteId/day/:todayDate/:foremanId" element={<CheckinPage />} />
        </Routes>
        <Header />
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/jobSites" element={<ProtectedRoute />}>
            <Route path="/jobSites/:jobSiteId" element={<JobSitePage />} />
            <Route path="/jobSites" element={<JobSiteListPage />} />
          </Route>
          <Route path="/employees" element={<ProtectedRoute />}>
            <Route path="/employees" element={<EmployeeList />} />
          </Route>
          <Route path="/map" element={<ProtectedRoute />}>
            <Route path="/map" element={<MapPage />} />
          </Route>
          <Route path="/users" element={<ProtectedRoute />}>
            <Route path="/users/:id?" element={<UserList />} />
          </Route>
          <Route path="/companies" element={<ProtectedRouteSuperAdmin />}>
            <Route path="/companies" element={<CompaniesPage />} />
            <Route path="/companies/:id?" element={<CompanyPage />} />
          </Route>
          <Route path="/reports" element={<ProtectedRouteAdmin />}>
            <Route path="/reports" element={<ReportsPage />} />
          </Route>
          <Route path="/settings" element={<ProtectedRouteAdmin />}>
            <Route path="/settings" element={<SettingsPage />} />
          </Route>
          <Route path="/" element={<LoginPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
