// src/ListPage.tsx
import React, {useEffect} from 'react';
import './jobSite.scss';
import {fetchAPI} from "../../Api";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {JobSite} from "../../store/interfaces";
import {useParams} from "react-router-dom";

const JobSitePage: React.FC = () => {
  const params = useParams();

  const user = useSelector((state: RootState) => state.user.user);

  const [jobSite, setJobSite] = React.useState<JobSite | null>();
  const [shortName, setShortName] = React.useState<string>('');

  useEffect(() => {
    async function fetchData() {
      if (!user) {
        return;
      }

      try {
        const response = await fetchAPI(user, 'GET', 'jobSites/' + params.jobSiteId);

        if (!response.ok) {
          throw new Error('Fetch Job Site Failed');
        }

        const data = await response.json();
        setJobSite(data);
        setShortName(data.shortName);
      } catch (error) {
        console.error('Get Job Site error:', error);
      }
    }

    fetchData();
  }, [user]);

  const saveShortName = async () => {
    const response = await fetchAPI(undefined, 'PATCH', `jobSites/${jobSite?.id}`, {
      shortName
    });
    if (!response.ok) {
      throw new Error('Save Short Name Failed');
    }
  }

  return (
      <div className="jobSite-container">
        <h2>{jobSite?.name}</h2>
        <div className="jobSite-info">
          <p>{jobSite?.address}</p>
          <p>{jobSite?.city}, {jobSite?.state} {jobSite?.zip}</p>
          <p>Short Name (Max 6 Characters)</p>
          <p><input
              value={shortName}
              maxLength={6}
              onChange={(ret) => {
            setShortName(ret.target.value)
          }} /> </p>
          <button onClick={() => {
            saveShortName()
          }}>Save</button>
        </div>
      </div>
  );
};

export default JobSitePage;
