import React, { useCallback, useMemo, useState } from 'react';
import './reports.scss';
import { fetchAPI } from "../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ReportPayroll, ReportPayrollDetail } from "../../store/interfaces";
import CsvDownloader from 'react-csv-downloader';
import CustomDatePicker from '../../pieces/customDatePicker/customDatePicker';

const ReportsPage: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const company = useSelector((state: RootState) => state.companies.currentCompany);
  const startingDate = new Date();
  const dow = company?.Settings?.dow


  const [report, setReport] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(startingDate);

  const getColumns = useMemo(() => {
    switch (report) {
      case 'payrollWeekly':
      case 'payrollDaily':
        return [
          { id: 'date', displayName: 'Date' },
          { id: 'employee', displayName: 'Employee number and Name' },
          { id: 'jobSite', displayName: 'Job' },
          { id: 'costCode', displayName: 'Cost Code' },
          { id: 'hours', displayName: 'Hours' },
        ];
      default:
        return [];
    }
  }, [report]);

  const getData = useCallback(async (): Promise<any[]> => {
    if (!user) {
      return [];
    }
    let response: Response | null = null;
    try {
      const fixedDate = selectedDate.toISOString().split('T')[0];
      switch (report) {
        case 'payrollWeekly':
          response = await fetchAPI(user, 'GET', `reports/payrollWeekly/company/${user.companyId}/date/${fixedDate}`);

          if (!response.ok) {
            throw new Error('Fetch Job Site Failed');
          }
          const reportDataWeekly = (await response?.json()) as ReportPayroll;

          return reportDataWeekly.rows.map((line: ReportPayrollDetail) => ({
            date: line.date,
            employee: line.employeeNumberAndName,
            jobSite: line.job,
            costCode: line.costCode,
            hours: line.hours,
          }));
        case 'payrollDaily':
          response = await fetchAPI(user, 'GET', `reports/payrollDaily/company/${user.companyId}/date/${fixedDate}`);

          if (!response.ok) {
            throw new Error('Fetch Job Site Failed');
          }
          const reportDataDaily = (await response?.json()) as ReportPayroll;

          return reportDataDaily.rows.map((line: ReportPayrollDetail) => ({
            date: line.date,
            employee: line.employeeNumberAndName,
            jobSite: line.job,
            costCode: line.costCode,
            hours: line.hours,
          }));
        default:
          return [];
      }
    } catch (error) {
      console.error('Fetch Job Site Failed:', error);
    }

    return [];
  }, [report, selectedDate, user]);

  const reportPicker = useMemo(() => {
    const reports = [{ id: 'payrollWeekly', displayName: 'Payroll Weekly' }, {id: 'payrollDaily', displayName: 'Payroll Daily'}]

    return (
        <select className="report-select" value={report || 'noneSelected'} onChange={(e) => setReport(e.target.value)}>
          <option value="noneSelected" disabled>Select a report</option>
          {reports.map((r) => (
              <option key={r.id} value={r.id}>{r.displayName}</option>
          ))}
        </select>
    )
  }, [report]);

  const payrollWeekly = useMemo(() => (
      <div className="report-details">
        <h2>Payroll Report - Weekly</h2>
        <div className="report-info">
          <CustomDatePicker selectedDate={selectedDate} dow={dow || 0} onDateChange={setSelectedDate} customFilter={'weekly'} />
          <CsvDownloader
              datas={getData}
              columns={getColumns}
              filename={`PayrollReportWeekly_${selectedDate.toISOString().split('T')[0]}.csv`}
          >
            <button className="report-button">Run Report</button>
          </CsvDownloader>
        </div>
      </div>
  ), [getColumns, getData, selectedDate, dow]);

  const payrollDaily = useMemo(() => (
      <div className="report-details">
        <h2>Payroll Report - Daily</h2>
        <div className="report-info">
          <CustomDatePicker selectedDate={selectedDate} dow={dow || 0} onDateChange={setSelectedDate} />
          <CsvDownloader
              datas={getData}
              columns={getColumns}
              filename={`PayrollReportDaily_${selectedDate.toISOString().split('T')[0]}.csv`}
          >
            <button className="report-button">Run Report</button>
          </CsvDownloader>
        </div>
      </div>
  ), [getColumns, getData, selectedDate, dow]);

  return (
      <div className="reports-container">
        {reportPicker}
        {report === 'payrollWeekly' && payrollWeekly}
        {report === 'payrollDaily' && payrollDaily}
      </div>
  );
};

export default ReportsPage;
