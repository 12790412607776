import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface User {
  email: string;
  role: string;
  firstName: string;
  lastName: string;
  token: string;
  companyId: number;
}

interface UserState {
  user: User | null;
}

const initialState: UserState = {
  user: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(
      state,
      action: PayloadAction<{
        email: string;
        role: string;
        firstName: string;
        lastName: string;
        token: string;
        companyId: number;
      }>,
    ) {
      state.user = action.payload;
    },
    clearUser(state) {
      state.user = null;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
