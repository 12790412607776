import React, {useState, ChangeEvent, useEffect} from 'react';
import './settings.scss';
import {fetchAPI} from "../../Api";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";

const SettingsPage: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.user);

  const [firstDayOfWeek, setFirstDayOfWeek] = useState<number>(0);

  useEffect(() => {
    if (!user) return;
    const getSettings = async () => {
      const gotSettings = await fetchAPI(user, 'GET', `companies/settings/${user.companyId}`);
      if (!gotSettings.ok) {
        throw new Error('Fetch Settings Failed');
      }
      const theCompany = await gotSettings.json();
      const theSettings = theCompany.Settings;
      if (theSettings?.settings && theSettings.settings.length > 0) {
        const decodedSettings = JSON.parse(theSettings.settings);
        setFirstDayOfWeek(decodedSettings.firstDayOfWeek);
      }
    }
    getSettings();
  }, [user]);

  const handleFirstDayOfWeekChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setFirstDayOfWeek(parseInt(event.target.value));
  };

  const handleSave = async () => {
    if (!user) {
      return;
    }
    await fetchAPI(user, 'POST', `companies/settings/${user.companyId}`, { settings: JSON.stringify({ firstDayOfWeek }) });
  };

  return (
      <div className="settings-container">
        <div className="section">
          <h2 className="section-title">Settings</h2>
          <div className="toggle-container">
            <label className="toggle-label" htmlFor="firstDayOfWeek">First Day of the Week</label>
            <select
                id="firstDayOfWeek"
                value={firstDayOfWeek}
                onChange={handleFirstDayOfWeekChange}>
              <option value={0}>Sunday</option>
              <option value={1}>Monday</option>
            </select>
          </div>
        </div>
        {/*<div className="section">*/}
        {/*  <h2 className="section-title">Language</h2>*/}
        {/*  <select className="language-select" value={language} onChange={handleLanguageChange}>*/}
        {/*    <option value="en">English</option>*/}
        {/*    <option value="es">Spanish</option>*/}
        {/*    <option value="fr">French</option>*/}
        {/*    <option value="de">German</option>*/}
        {/*  </select>*/}
        {/*</div>*/}
        <button className="save-button" onClick={handleSave}>Save Settings</button>
      </div>
  );
};

export default SettingsPage;
