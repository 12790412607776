import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import './checkin.scss';
import {fetchAPI} from '../../Api';
import {useGeolocated} from "react-geolocated";
import {computeDistance} from "../../pieces/functions";

const CheckinPage: React.FC = () => {
  console.log('CheckinPage')
  const [phoneCode, setPhoneCode] = useState('');
  const [checkedIn, setCheckedIn] = useState<{ [key: string]: any; } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [jobSite, setJobSite] = useState<any | null>(null);
  const params = useParams();

  const {coords, isGeolocationAvailable, isGeolocationEnabled} =
      useGeolocated({
        positionOptions: {
          enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
      });


  useEffect(() => {
    const fetchJobSite = async () => {
      const results = await fetchAPI(undefined, 'GET', `jobsites/${params.jobSiteId}`)
      const data = await results.json();
      console.log('JobSite', data);
      setJobSite(data);
    }
    fetchJobSite();

  }, [params.jobSiteId]);

  console.log('Got Coords', coords, isGeolocationAvailable, isGeolocationEnabled)

  const handleCheckin = async (e: React.FormEvent) => {
    console.log('handleCheckin')
    e.preventDefault();
    setError(null); // Reset error state

    // Call the API to check in the user
    try {
      const response = await fetchAPI({
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        token: 'X',
        companyId: 0
      }, 'POST', 'users/checkin', {
        jobSiteId: Number(params.jobSiteId),
        todayDate: params.todayDate,
        phoneCode: phoneCode,
        foremanId: Number(params.foremanId)
      });

      console.log('response', response)

      if (!response.ok) {
        setError(`Checkin failed: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Checkin response', data);
      if (data) {
        console.log('setting checked in to', data)
        setCheckedIn(data);
      }
      // Dispatch user data to Redux store

    } catch (error) {
      console.error('Login error:', error);
      setError('Login failed. Please check your credentials.');
    }
  };

  if (false && (!isGeolocationEnabled || !coords?.latitude || !coords?.longitude || !jobSite?.latitude || !jobSite?.longitude)) {
    return (
        <>
          <div className="checkin-container">
            <h2>You must enable location services to check in.</h2>
          </div>
          <div className="checkin-container">
            <h3>{isGeolocationEnabled} - {isGeolocationAvailable} - {coords?.latitude}, {coords?.longitude} - {jobSite?.latitude}, {jobSite?.longitude}</h3>
          </div>
        </>
    )
  }

  const distanceInMeters = computeDistance(coords?.latitude, coords?.longitude, jobSite?.latitude, jobSite?.longitude);
  if (false && distanceInMeters > 100) {
    return (
        <div className="checkin-container">
          <h2>You are too far from the job site to check in. {distanceInMeters}</h2>
        </div>
    )
  }

  return (false && !isGeolocationAvailable) ? (
      <div className="checkin-container">
        <h2>You must allow us to see your location.</h2>
      </div>
  ) : (
      <div className="checkin-container">
        {checkedIn === null && <form className="checkin-form" onSubmit={handleCheckin}>
          <h2>Check-In</h2>
          {error && <p className="error">{error}</p>}
          <div className="form-group">
            <label htmlFor="phoneCode">Phone Number / Número de Teléfono</label>
            <input
                type="number"
                id="phoneCode"
                autoFocus={true}
                value={phoneCode}
                onFocus={() => setError(null)}
                onChange={(e) => setPhoneCode(e.target.value)}
                required
            />
          </div>
          <button type="submit" className="checkin-button">
            Check In
          </button>
        </form>}
        {checkedIn?.statusCode >= 300 && <h2>Error Checking in. {checkedIn?.statusCode} {JSON.stringify(checkedIn)}</h2>}
        {checkedIn !== null && !checkedIn?.statusCode && <h2>Welcome {checkedIn.name}, you are Checked In!</h2>}
      </div>
  );
};

export default CheckinPage;
