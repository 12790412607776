import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import clsx from 'clsx';
import './companyList.scss';
import {RootState} from '../../store/store';
import {fetchAPI} from '../../Api';
import CompanyForm from './companyForm';
import {Link, useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {Company} from "../../store/interfaces";

const CompaniesPage: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const [companies, setCompanies] = React.useState<Company[]>([]);
  const [showForm, setShowForm] = React.useState<boolean>(false);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      if (!user) {
        return;
      }

      try {
        const response = await fetchAPI(user, 'GET', 'companies');

        if (!response.ok) {
          throw new Error('Fetch Companies Failed');
        }

        const data = await response.json();
        setCompanies(data);
        console.log('Get Companies successful', data);
      } catch (error) {
        console.error('Get Companies error:', error);
      }
    }

    fetchData();
  }, [user]);

  if (!companies) {
    return <p>Fetching companies...</p>;
  }

  if (!user) {
    return <p>Not authorized</p>;
  }

  const submitForm = async (formData: Company) => {
    delete formData.createdAt;
    if (user?.role !== 'SUPERADMIN') {
      return;
    }

    let response;
    if (params.id === '0' || !params.id) {
      delete formData.id;
      delete formData.Employee
      delete formData.JobSite
      delete formData.CostCode
      delete formData.Settings

      console.log('formData', formData);
      response = await fetchAPI(user, 'POST', `companies`, formData);
      console.log('response', response);
    } else {
      formData.id = Number(params.id);
      response = await fetchAPI(
          user,
          'PATCH',
          `companies/${params.id}`,
          formData,
      );
    }
    if (!response.ok) {
      throw new Error('Save Company Failed');
    }
    setCompanies([...companies.filter((c) => c.id !== formData.id), formData]);
    navigate('/companies');
  };

  const viewCompanies = (
      <>
        <ul className="company-list">
          <li key="-1" className={clsx('company-item', 'company-header')}>
            <div className="company-name">Name</div>
            <div className="company-location">Location</div>
            <div className="company-view">Upload</div>
          </li>
          {companies.map((company, index) => {
            if (!company.id) {
              return <></>
            } else {
              return <li key={index} className="company-item">
                <div className="company-name">{company.name}</div>
                <div className="company-location">{company.location}</div>
                <div className="company-view">
                  <Link to={`/companies/${company.id}`}>
                    <button>View</button>
                  </Link>
                </div>
              </li>
            }
          })
          }
        </ul>
        {!companies.length && <h2>No companies found</h2>}
      </>
  );

  return (
      <>
        <div className="company-container">
          <div className={'company-topline'}>
            <button onClick={() => { setShowForm((formState) => !formState ) }}>{ showForm ? 'Hide Form' : 'Add New' }</button>
          </div>
          { showForm ? (
              <CompanyForm
                  onSubmit={submitForm}
                  initialData={companies.find((c) => c.id === Number(params.id))}
              />
          ) : (
              viewCompanies
          )}
        </div>
      </>
  );
};

export default CompaniesPage;
