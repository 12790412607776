// src/Header.tsx
import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {clearUser} from '../../store/userSlice';
import {RootState} from '../../store/store';
import './header.scss';

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user);

  const handleLogout = () => {
    dispatch(clearUser());
    navigate('/login');
  };

  return (
      <header className="header">
        <div className="logo">
          <img src="/logo.png" alt="Company Logo"/>
        </div>
        {user && (user.role === 'USER' || user.role === 'ADMIN') && (
            <nav className="nav-menu">
              <Link to="/map" className="nav-item">
                Map
              </Link>
              <Link to="/jobSites" className="nav-item">
                Job Sites
              </Link>
              <Link to="/employees" className="nav-item">
                Employees
              </Link>
              <Link to="/users" className="nav-item">
                Users
              </Link>
              {user?.role === 'ADMIN' && (
                  <Link to="/reports" className="nav-item">
                    Reports
                  </Link>
              )}
              {user?.role === 'ADMIN' && (
                  <Link to="/settings" className="nav-item">
                    Settings
                  </Link>
              )}
            </nav>
        )}
        {user?.role === 'SUPERADMIN' && (
            <nav className="nav-menu">
              <Link to="/companies" className="nav-item">
                Companies
              </Link>
            </nav>
        )}
        {user ? (
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
        ) : (
            <button className="logout-button" onClick={() => navigate('/login')}>
              Login
            </button>
        )}
      </header>
  );
};

export default Header;
