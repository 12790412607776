import React from 'react';
import './employeeList.scss';

const employees = [
  { name: 'John Doe', position: 'Project Manager' },
  { name: 'Jane Smith', position: 'Accountant' },
  { name: 'Alice Johnson', position: 'Construction Worker' },
  { name: 'Robert Brown', position: 'Foreman' },
  { name: 'Emily Davis', position: 'Architect' },
];

const EmployeeList: React.FC = () => {
  return (
    <div className="employees-container">
      <h2>Employees</h2>
      <ul className="employee-list">
        {employees.map((employee, index) => (
          <li key={index} className="employee-item">
            <div className="employee-name">{employee.name}</div>
            <div className="employee-position">{employee.position}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EmployeeList;
