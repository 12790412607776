import React from 'react';
import DatePicker from 'react-datepicker';
import { addDays, isAfter, isBefore, setDay } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

interface CustomDatePickerProps {
  selectedDate: Date;
  dow?: number; // Optional day of the week filter
  onDateChange: (prevState: Date) => void;
  customFilter?: string; // Custom filter function prop
}

const filterWeekly = (date: Date, dow: number) => {
  const day = date.getDay();
  const startDate = new Date('2024-07-22');
  const today = new Date();
  return day === dow && isAfter(date, startDate) && isBefore(date, addDays(setDay(today, 1), 1));
};

const notFuture = (date: Date) => {
  return isBefore(date, new Date());
};

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({ selectedDate, dow, onDateChange, customFilter }) => {
  const filterDate = (date: Date) => {
    switch (customFilter) {
      case 'weekly':
        return filterWeekly(date, dow!) && notFuture(date);
      default:
        return notFuture(date);
    }
  };

  while (!filterDate(selectedDate)) {
    selectedDate = addDays(selectedDate, -1);
  }

  return (
      <DatePicker
          selected={selectedDate}
          /* @ts-ignore */
          onChange={onDateChange}
          filterDate={filterDate}
          dateFormat="yyyy-MM-dd"
      />
  );
};

export default CustomDatePicker;
