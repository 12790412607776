// src/ListPage.tsx
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import './userList.scss';
import { RootState } from '../../store/store';
import { fetchAPI } from '../../Api';
import UserForm from './userForm';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export type User = {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  password: string;
};

const UserPage: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const [users, setUsers] = React.useState<User[]>([]);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      if (!user) {
        return;
      }

      try {
        const response = await fetchAPI(user, 'GET', 'users');

        if (!response.ok) {
          throw new Error('Fetch Users Failed');
        }

        const data = await response.json();
        setUsers(data);
        console.log('Get Users successful', data);
      } catch (error) {
        console.error('Get Users error:', error);
      }
    }
    fetchData();
  }, [user]);

  if (!users) {
    return <p>Fetching users...</p>;
  }

  const submitForm = async (formData: User) => {
    if (!user) {
      return;
    }

    let response;
    if (params.id === '0') {
      delete formData.id;
      console.log('formData', formData);
      response = await fetchAPI(user, 'POST', `users`, formData);
    } else {
      formData.id = Number(params.id);
      response = await fetchAPI(
          user,
          'PATCH',
          `users/${params.id}`,
          formData,
      );
    }
    if (!response.ok) {
      throw new Error('Save User Failed');
    }
    setUsers([...users.filter((c) => c.id !== formData.id), formData]);
    navigate('/users');
  };

  const viewUsers = (
    <ul className="user-list">
      <li key="-1" className={clsx('user-item', 'user-header')}>
        <div className="user-name">Name</div>
        <div className="user-employees">Role</div>
        <div className="user-location">Edit</div>
      </li>
      {users.map((user, index) => (
        <li key={index} className="user-item">
          <div className="user-name">{user.firstName} {user.lastName}</div>
          <div className="user-role">{user.role}</div>
          <div className="user-edit">
            <Link to={`/users/${user.id}`}>
              <button>Edit</button>
            </Link>
          </div>
        </li>
      ))}
    </ul>
  );

  return (
    <>
      <div className="user-container">
        <div className={'user-topline'}>
          <h2>Users</h2>
          <Link to={`/users/0`}>
            <button>Add New</button>
          </Link>
        </div>
        {Number(params.id) >= 0 ? (
          <UserForm
            onSubmit={submitForm}
            initialData={users.find((c) => c.id === Number(params.id))}
          />
        ) : (
          viewUsers
        )}
      </div>
    </>
  );
};

export default UserPage;
