import React, { useEffect, useRef } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import './mapPage.scss';
import {JobSite, WorkDayDetail} from "../../store/interfaces";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {fetchAPI} from "../../Api";
import {useNavigate} from "react-router-dom";

const containerStyle = {
  width: '100%',
  height: 'Calc(100vh - 300px)',
};

const center = {
  lat: 39.8283,
  lng: -98.5795,
};

const MapPage: React.FC = () => {

  const navigate = useNavigate()

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '', // Replace with your API key
  });

  const [jobSites, setJobSites] = React.useState<JobSite[]>([]);
  const [bounds, setBounds] = React.useState<google.maps.LatLngBounds | null>(null);

  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    async function fetchData() {
      if (!user) {
        return;
      }

      try {
        const response = await fetchAPI(user, 'GET', 'jobSites/company/' + user.companyId);
        if (!response.ok) {
          throw new Error('Fetch Job Sites Failed');
        }
        const data = await response.json() as unknown as JobSite[];
        console.log('data', data);
        const updated = data.map((jobSite) => {
          const manager = jobSite.Employee.name.split(' ').filter((piece) => piece.length > 0).map((piece) => piece[0]).join('');
          // Generate a count of all employees that have worked at this job site
          const workDays = jobSite.WorkDayDetail.map((workDayDetail: WorkDayDetail) => workDayDetail.workDayId); // This is unique to each employee and day
          // Count the number of unique work days
          const uniqueWorkDays = workDays.filter((value, index, self) => self.indexOf(value) === index);
          console.log('uniqueWorkDays', uniqueWorkDays);
          return {
            ...jobSite,
            manager,
            employees: uniqueWorkDays.length,
          };
        })
        console.log('updated', updated);
        setJobSites(updated);
      } catch (error) {
        console.error('Get Company error:', error);
      }
    }

    fetchData();
  }, [user]);

  const mapRef = useRef<google.maps.Map | null>(null);

  const handleMapLoad = (map: google.maps.Map) => {
    mapRef.current = map;

    const bounds = new google.maps.LatLngBounds();

    jobSites.forEach((jobSite) => {
      const myLatLng = new google.maps.LatLng(jobSite.latitude, jobSite.longitude);
      bounds.extend(myLatLng);
      const fillColor = jobSite.employees > 0 ? 'blue' : 'yellow';
      const textColor = jobSite.employees > 0 ? 'white' : 'black';

      const marker = new google.maps.Marker({
        position: myLatLng,
        map,
        icon: {
          url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
              `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
  <polygon points="20 40 40 0 0 0" fill="${fillColor}"/>
  <text x="20" y="10" font-family="Roboto" font-size="8" fill="${textColor}" text-anchor="middle">${jobSite.shortName}</text>
  <text x="20" y="19" font-family="Roboto" font-size="8" fill="${textColor}" text-anchor="middle">${jobSite.manager}</text>
  <text x="20" y="28" font-family="Roboto" font-size="8" fill="${textColor}" text-anchor="middle">${jobSite.employees}</text>
</svg>`
          )}`,
          scaledSize: new google.maps.Size(40, 40),
        },
        title: jobSite.shortName,
      });

      marker.addListener("click", () => {
        navigate(`/jobSites/${jobSite.id}`)
      });
    });

    setBounds(bounds);
    map.fitBounds(bounds);
  };

  if (loadError) {
    return <div>Error loading map: {loadError.message}</div>;
  }

  if (!isLoaded) {
    return <div>Loading Map...</div>;
  }

  return jobSites.length > 1 ? (
      <div className="jobSite-container">
        <h2>Locations on Map</h2>
        <GoogleMap
            mapContainerStyle={containerStyle}
            onLoad={handleMapLoad}
            center={bounds?.getCenter() || center}
            mapTypeId='satellite'
        />
      </div>
  ) : <>No Job Sites</>;
};

export default MapPage;
