import React, {useEffect} from 'react';
import './jobSiteList.scss';
import {fetchAPI} from "../../Api";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {JobSite} from "../../store/interfaces";

const JobSiteListPage: React.FC = () => {
  const [jobSites, setJobSites] = React.useState<JobSite[]>([]);

  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    async function fetchData() {
      if (!user) {
        return;
      }

      try {
        const response = await fetchAPI(user, 'GET', `jobSites/company/${user.companyId}`);

        if (!response.ok) {
          throw new Error('Fetch Job Sites Failed');
        }

        const data = await response.json();
        setJobSites(data);
      } catch (error) {
        console.error('Get Company error:', error);
      }
    }

    fetchData();
  }, [user]);

  console.log('jobSites', jobSites);

  return (
      <div className="jobSite-container">
        <h2>Job Sites</h2>
        <table className="jobSite-table">
          <thead>
          <tr>
            <th>Name</th>
            <th>Foreman</th>
            <th>Employee Count</th>
            <th>Current Cost Codes</th>
          </tr>
          </thead>
          <tbody>
          {jobSites.map((jobSite, index) => (
              <tr key={index}>
                <td>{jobSite.name}</td>
                <td>Fore Man Name</td>
                <td>{index + 5}</td>
                <td>CEMENT, FRAMING</td>
                {/*<td>{jobSite.foreman}</td> /!* Assuming foreman is a property *!/*/}
                {/*<td>{jobSite.employeeCount}</td> /!* Assuming employeeCount is a property *!/*/}
                {/*<td>{jobSite.currentCostCodes.join(', ')}</td> /!* Assuming currentCostCodes is an array *!/*/}
              </tr>
          ))}
          </tbody>
        </table>
      </div>
  );
};

export default JobSiteListPage;
