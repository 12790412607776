import React, { useEffect, useState } from 'react';
import {Company} from "../../store/interfaces";

interface CompanyFormProps {
  onSubmit: (formData: Company) => void;
  initialData?: Company | undefined;
}

interface CompanyFormErrors {
  name: string;
  location: string;
}

const CompanyForm: React.FC<CompanyFormProps> = ({ onSubmit, initialData }) => {
  const [formData, setFormData] = useState<Company>(
    initialData || { id: 0, name: '', location: '', CostCode: [], JobSite: [], Employee: [], Settings: { dow: 0 } },
  );
  const [errors, setErrors] = useState<Partial<CompanyFormErrors>>({});

  useEffect(() => {
    setFormData(initialData || { id: 0, name: '', location: '', CostCode: [], JobSite: [], Employee: [], Settings: { dow: 0 } });
  }, [initialData]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    console.log('name', name, 'value', value);
    setFormData({
      ...formData,
      [name]: name === 'employees' ? Number(value) : value,
    });
  };

  const validate = () => {
    const newErrors: Partial<CompanyFormErrors> = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (formData.name.length > 50)
      newErrors.name = 'Name must be less than 50 characters';
    if (!formData.location) newErrors.location = 'Location is required';
    if (formData.location.length > 100)
      newErrors.location = 'Location must be less than 100 characters';

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validate()) {
      onSubmit(formData);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </label>
        {errors.name && <p style={{ color: 'red' }}>{errors.name}</p>}
      </div>
      <div>
        <label>
          Location:
          <input
            type="text"
            name="location"
            value={formData.location}
            onChange={handleChange}
          />
        </label>
        {errors.location && <p style={{ color: 'red' }}>{errors.location}</p>}
      </div>
      <button type="submit">Submit</button>
    </form>
  );
};

export default CompanyForm;
