import { User } from './store/userSlice';

export const fetchAPI = async (
  user: User | undefined,
  method: string,
  path: string,
  data?: any,
): Promise<Response> => {
  const url = `${process.env.REACT_APP_API_URL}/${path}`;
  const headers = user
    ? {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    : {
        'Content-Type': 'application/json',
        Authorization: 'None',
      };

  console.log('fetchAPI', url, method, data, headers)

  return await fetch(url, {
    method,
    body: data ? JSON.stringify(data) : undefined,
    headers,
  });
};
