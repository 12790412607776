export const computeDistance = (prevLat: number | undefined, prevLong: number | undefined, lat: number | undefined, long: number | undefined) => {
  if (!prevLat || !prevLong || !lat || !long) return 1000000;
  const prevLatInRad = toRad(prevLat);
  const prevLongInRad = toRad(prevLong);
  const latInRad = toRad(lat);
  const longInRad = toRad(long);

  return (
      // In kilometers
      6377.830272 *
      Math.acos(
          Math.sin(prevLatInRad) * Math.sin(latInRad) +
          Math.cos(prevLatInRad) * Math.cos(latInRad) * Math.cos(longInRad - prevLongInRad),
      )
  );
}

function toRad(angle: number) {
  return (angle * Math.PI) / 180;
}
