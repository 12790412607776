import React, { useEffect, useState } from 'react';
import { User } from './userList';

interface UserFormProps {
  onSubmit: (formData: User) => void;
  initialData?: User | undefined;
}

interface UserFormErrors {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: string;
}

const UserForm: React.FC<UserFormProps> = ({ onSubmit, initialData }) => {
  const [formData, setFormData] = useState<User>(
    initialData || { id: 0, firstName: '', lastName:'', email: '', role: 'USER', password: '' },
  );
  const [errors, setErrors] = useState<Partial<UserFormErrors>>({});

  useEffect(() => {
    setFormData(initialData || { id: 0, firstName: '', lastName:'', email: '', role: 'USER', password: ''});
  }, [initialData]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    console.log('name', name, 'value', value);
    setFormData({
      ...formData,
      [name]: name === 'employees' ? Number(value) : value,
    });
  };

  const validate = () => {
    const newErrors: Partial<UserFormErrors> = {};

    if (!formData.firstName) newErrors.firstName = 'Name is required';
    if (formData.firstName.length > 50) {
      newErrors.firstName = 'First name must be less than 50 characters';
    }
    if (!formData.lastName) newErrors.lastName = 'Name is required';
    if (formData.lastName.length > 50) {
      newErrors.lastName = 'Last name must be less than 50 characters';
    }
    if (!['USER', 'ADMIN'].includes(formData.role)) {
      newErrors.role = 'Location is required to be USER or ADMIN';
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validate()) {
      onSubmit(formData);
    }
  };

  return (
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            First Name:
            <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
            />
          </label>
          {errors.firstName && <p style={{color: 'red'}}>{errors.firstName}</p>}
        </div>
        <div>
          <label>
            Last Name:
            <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
            />
          </label>
          {errors.lastName && <p style={{color: 'red'}}>{errors.lastName}</p>}
        </div>
        <div>
          <label>
            Email:
            <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
            />
          </label>
          {errors.email && <p style={{color: 'red'}}>{errors.email}</p>}
        </div>
        <div>
          <label htmlFor="role">Role: </label>
          <select
              id="role"
              value={formData.role}
              onChange={handleChange}
          >
            <option value="ADMIN">ADMIN</option>
            <option value="USER">USER</option>
          </select>
          {errors.role && <p style={{color: 'red'}}>{errors.role}</p>}
        </div>
        <button type="submit">Submit</button>
      </form>
  );
};

export default UserForm;
