import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Company} from "./interfaces";

interface CompaniesState {
  companies: Company[];
  currentCompany: Company | null;
}

const initialState: CompaniesState = {
  companies: [],
  currentCompany: null,
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompanies(state, action: PayloadAction<Company[]>) {
      state.companies = action.payload;
    },
    setCurrentCompany(state, action: PayloadAction<Company>) {
      state.currentCompany = action.payload;
    },
    addCompany(state, action: PayloadAction<Company>) {
      state.companies.push(action.payload);
    },
    removeCompany(state, action: PayloadAction<number>) {
      state.companies = state.companies.filter(
        (company) => company.id !== action.payload,
      );
    },
  },
});

export const { setCompanies, setCurrentCompany, addCompany, removeCompany } =
  companiesSlice.actions;
export default companiesSlice.reducer;
